import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../app/pipes/pipes.module';
import { DatePipe } from '@angular/common'
import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import {
//   AgmCoreModule
// } from '@agm/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// import { AndreAssousInitiateReturnComponent } from './andre-assous-initiate-return/andre-assous-initiate-return.component';

import { CustomerinitiatereturnModule } from './customerinitiatereturn/customerinitiatereturn.module';
  
// import { GroupByChannelIdComponent } from './group-by-channel-id/group-by-channel-id.component';

// import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  imports: [
    // NgxPaginationModule,
    BrowserAnimationsModule,
    FormsModule,
    CustomerinitiatereturnModule,
    ReactiveFormsModule,
    HttpModule,
    RouterModule,
    PipesModule,
    HttpClientModule,
    AppRoutingModule,
    MatChipsModule,
    MatSlideToggleModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
    // })
  ],
  declarations: [
    AppComponent,
    // AndreAssousInitiateReturnComponent,


  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
