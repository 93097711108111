import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AndreAssousInitiateReturnComponent } from './customerinitiatereturn/andre-assous-initiate-return/andre-assous-initiate-return.component';
import { SuccessComponent } from './customerinitiatereturn/success/success.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'initiate-return',
    pathMatch: 'full',
  },
  { path: 'initiate-return', component: AndreAssousInitiateReturnComponent },
  { path: 'success', component: SuccessComponent }

  // {
  //   path: '',
  //   component: AndreAssousInitiateReturnComponent,
  //   children: [{
  //     path: '',
  //     loadChildren: './customerinitiatereturn/customerinitiatereturn.module#CustomerinitiatereturnModule'
  //   }]
  // }


];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: false
    })
  ],
  exports: [
  ],
})



@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always'
  })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
