import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AndreAssousInitiateReturnComponent } from './andre-assous-initiate-return/andre-assous-initiate-return.component';
// import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { SuccessComponent } from './success/success.component';
 
@NgModule({
  declarations: [AndreAssousInitiateReturnComponent, SuccessComponent],
  imports: [
    CommonModule,
    FormsModule
  ]
})
export class CustomerinitiatereturnModule { }
